import React, { useState, useEffect } from 'react';
import { ArrowSmallLeftIcon, CheckIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';

export default function NewDroplet({ connectedUser }) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [loadingRegion, setLoadingRegion] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [doRegions, setDoRegions] = useState([]);
  const { id } = useParams();

  const [droplets, setDroplets] = useState([]);

  const initialData = {
    do_account_id: id,
    ssh_user: 'root',
    status: 'active',
    name: '',
    ssh_password: '',
    ssh_key: '',
    region: 'nyc1',
    ram: 1,
    image: 'centos-7-x64',
    cpu: 1,
  };
  const [DODropletData, setDODropletData] = useState(initialData);


  useEffect(() => {
    loadRegions()
  }, []);


  const loadRegions = async () => {
    setLoadingRegion(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}/do-accounts?do_account_id=${id}`;

      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'access-key': connectedUser.token,
        },
      });
      const data = await response.json();
      if (data.hasOwnProperty('do_accounts') && data.do_accounts.length) {
        getDORegions(data.do_accounts[0]['token']);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoadingRegion(false);
    }
  }

  const getDORegions = (token) => {
    try {
      fetch('https://api.digitalocean.com/v2/regions', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(res => {
          res.json().then(data => {
            setDoRegions(data.regions.filter(item => item.available == true));
            setLoadingRegion(false);
          })
        })
        .catch(err => {
          setLoadingRegion(false);
        })
        .finally(() => {
          // setLoadingRegion(false);
        })
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }

  const resetForm = () => {
    setDODropletData(initialData);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDODropletData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const payload = { ...DODropletData, ssh_password: null, ssh_user: null, };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/droplets`, {
        method: 'POST',
        headers: {
          'access-key': connectedUser.token,
          'content-type': 'application/json',
          'access-token': connectedUser.token
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // DO droplet creation successful
        toast.success('Digital Ocean droplet added successfully');
        navigate('/do-accounts/' + id)
      } else if (response.status >= 400 && response.status < 500) {
        // DO Droplet creation failed due to validation errors
        const errorData = await response.json();
        setValidationErrors(errorData);
        toast.warning('Cannot create Digital Ocean droplet!');
      } else {
        // Other error occurred
        toast.error('Something went wrong!');
      }
    } catch (error) {
      console.error('Error creating digital ocean droplet:', error);
      toast.error('An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const OSImages = [
    {
      name: 'Centos 7',
      slug: 'centos-7-x64',
    },
    {
      name: 'Ubuntu 20.04',
      slug: 'ubuntu-20-04-x64',
    },
    {
      name: 'Debian 10',
      slug: 'debian-10-x64',
    },
    {
      name: 'AlmaLinux 8',
      slug: 'almalinux-8-x64',
    },
    {
      name: 'Fedora 37',
      slug: 'fedora-37-x64',
    },
    {
      name: 'Ubuntu 22.04',
      slug: 'ubuntu-22-04-x64',
    },
    {
      name: 'Fedora 38',
      slug: 'fedora-38-x64',
    },
    {
      name: 'Almalinux 9',
      slug: 'almalinux-9-x64',
    }
  ];

  const addDroplet = (e) => {
    e.preventDefault();

    if (!(new RegExp(/^[a-z0-9\-\.]+$/i)).test(DODropletData.name)) {
      toast.warning('Invalid hostname!');
      return;
    }

    setDroplets([...droplets, DODropletData]);
    resetForm();
    toast.info('Droplet added and ready to be saved.');
  }

  const submitDroplets = async () => {
    if (droplets.length < 1) return;

    const promises = droplets.map(async (droplet) => {
      return await fetch(`${process.env.REACT_APP_API_URL}/droplets`, {
        method: 'POST',
        headers: {
          'access-key': connectedUser.token,
          'content-type': 'application/json',
          'access-token': connectedUser.token
        },
        body: JSON.stringify(droplet),
      })
    })


    Promise.all(promises)
      .then(values => {
        const success = values.reduce((prev, item) => {
          return item.ok && prev
        }, true);

        if (success) {
          setDroplets([]);
          toast.success((droplets.length > 1 ? 'Droplets have ' : 'Droplet has') + ' been saved successfully');
          setTimeout(() => {
            navigate('/do-accounts/' + id);
          }, 1500);
        }
        else
          toast.error('Can\'t create all the droplets, please verify for any typos!!');

        setIsLoading(false);
      })
      .catch(errors => {
        setIsLoading(false);
        toast.error('Something went wrong while trying to create the droplets!')
      })
  }

  return (
    <div className="flex justify-center items-center relative">
      <div className="bg-white p-8 rounded-lg shadow-lg w-2/3">
        <h2 className="text-primary-500 text-2xl font-bold mb-4 flex items-center ">
          <Link to={`/do-accounts/${id}`} className='cursor-pointer'>
            <ArrowSmallLeftIcon classNme='h-6 w-6 text-primary-500 mr-4 rounded-full border hover:border-primary-500' />
          </Link>
          New Digital Ocean Droplet
        </h2>

        <div className='block md:flex lg:flex xl:flex w-full'>
          {/*  */}
          <form className='md:w-1/2 lg:w-1/2 xl:w-1/2 w-full' onSubmit={addDroplet}>
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2 text-primary-500">
                Hostname
              </label>
              <input
                type="text"
                id="name"
                required={true}
                pattern={'^[a-zA-Z0-9\-\.]+$'}
                name="name"
                placeholder='Allowed characters: (a-z, A-Z, 0-9, . and -)'
                value={DODropletData.name}
                onChange={handleInputChange}
                className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
              />
              <p className='py-2 text-red-500 mt-2 rounded'>
                {validationErrors?.name && <span className="text-red-500">{validationErrors.name}</span>}
              </p>
            </div>

            <div className="mb-4 hidden">
              <label htmlFor="ssh_password" className="block mb-2 text-primary-500">
                SSH Password
              </label>
              <input
                type="text"
                id="ssh_password"
                required={false}
                name="ssh_password"
                value={DODropletData.ssh_password}
                onChange={handleInputChange}
                className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
              />
              <p className='py-2 text-red-500 mt-2 rounded'>
                {validationErrors?.ssh_password && <span className="text-red-500">{validationErrors.ssh_password}</span>}
              </p>
            </div>

            <div className="hidden mb-4">
              <label htmlFor="ssh_key" className="block mb-2 text-primary-500">
                SSH Key
              </label>
              <textarea
                type="text"
                id="ssh_key"
                required={false}
                name="ssh_key"
                value={DODropletData.ssh_key}
                onChange={handleInputChange}
                className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
              ></textarea>
              <p className='py-2 text-red-500 mt-2 rounded'>
                {validationErrors?.ssh_key && <span className="text-red-500">{validationErrors.ssh_key}</span>}
              </p>
            </div>

            <div className="mb-4">
              <label htmlFor="region" className="block mb-2 text-primary-500">
                {
                  loadingRegion ? 'Region (Loading ..)' : 'Region'
                }
              </label>

              <select
                id="region"
                required={true}
                readOnly={true}
                name="region"
                value={DODropletData.region}
                onChange={handleInputChange}
                className="border-b border-primary-500 px-2 py-1 w-80 outline-0 bg-gray-100"
              >
                {doRegions.map(region => (<option value={region.slug} key={region.slug}>{region.name}</option>))}
              </select>

              {/* <input
                type="text"
                id="region"
                required={true}
                readOnly={true}
                name="region"
                value={DODropletData.region}
                onChange={handleInputChange}
                className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
              /> */}

              <p className='py-2 text-red-500 mt-2 rounded'>
                {validationErrors?.region && <span className="text-red-500">{validationErrors.region}</span>}
              </p>
            </div>

            <div className="mb-4">
              <label htmlFor="ram" className="block mb-2 text-primary-500">
                RAM (1: 1GB, 2: 2GB ...)
              </label>
              <input
                type="number"
                pattern='[1-8]{1}'
                id="ram"
                required={true}
                name="ram"
                value={DODropletData.ram}
                onChange={handleInputChange}
                className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
              />
              <p className='py-2 text-red-500 mt-2 rounded'>
                {validationErrors?.ram && <span className="text-red-500">{validationErrors.ram}</span>}
              </p>
            </div>

            <div className="mb-4">
              <label htmlFor="cpu" className="block mb-2 text-primary-500">
                CPU (1,2,3,4,5,6)
              </label>
              <input
                type="number"
                placeholder="VCPU count"
                pattern='[1-6]{1}'
                id="cpu"
                required={true}
                name="cpu"
                value={DODropletData.cpu}
                onChange={handleInputChange}
                className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
              />
              <p className='py-2 text-red-500 mt-2 rounded'>
                {validationErrors?.cpu && <span className="text-red-500">{validationErrors.cpu}</span>}
              </p>
            </div>

            <div className="mb-4">
              <label htmlFor="image" className="block mb-2 text-primary-500">
                Image (OS)
              </label>
              <select
                id="image"
                required={true}
                readOnly={true}
                name="image"
                value={DODropletData.image}
                onChange={handleInputChange}
                className="border-b border-primary-500 px-2 py-1 w-80 outline-0 bg-gray-100"
              >
                {OSImages.map(image => (<option value={image.slug} key={image.slug}>{image.name}</option>))}
              </select>
              <p className='py-2 text-red-500 mt-2 rounded'>
                {validationErrors?.image && <span className="text-red-500">{validationErrors.image}</span>}
              </p>
            </div>

            <button
              type="submit"
              className="text-primary-500 px-4 py-2 rounded-full transition-all border border-primary-300 ring-primary-300 hover:ring-2"
              disabled={isLoading}
            >
              {isLoading ? '...' : 'Add'}
            </button>
          </form>

          {/*  */}
          <div className='md:w-1/2 lg:w-1/2 xl:w-1/2 w-full'>
            <h3 className='text-xl text-gray-500'>Preview</h3>
            {/* Droplets preview */}
            {
              droplets.map((item, index) => (
                <div className='p-2 shadow-md shadow-primary-100 relative w-2/3' key={index} id={`droplet-${index}`} >
                  <p className=''>Hostname: {item.name}</p>
                  <p className=''>Region: {item.region}</p>
                  <p className=''>Memory: {item.ram}GB</p>
                  <p className=''>CPU: {item.cpu}vCPU</p>
                  <p className=''>OS: {item.image}</p>
                  <button className='absolute top-2 right-[5%]' onClick={() => {
                    setDroplets(droplets.filter(x => x.name !== item.name))
                  }}>
                    <TrashIcon className='h-6 w-6 text-red-500' />
                  </button>
                </div>
              ))
            }
            {
              droplets.length > 0
                ? <button disabled={isLoading} onClick={submitDroplets}
                  className='flex mt-4 bg-primary-500 text-white px-4 py-2 rounded-full transition-all border border-white ring-primary-500 hover:bg-primary-500 hover:ring-2'>
                  <span className='mr-2'>{isLoading ? 'Wait ..' : 'Save'}</span>
                  <CheckIcon className='h-6 w-6 text-emerald-100' />
                </button>
                : ''
            }
          </div>
        </div>

      </div>
      <ToastContainer />
    </div>
  );

}
