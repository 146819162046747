import React, { useState, useEffect } from 'react';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from '../components/common';

export default function Profile({ token, handleProfileUpdated, currentProfilePhoto }) {
  const [profileData, setProfileData] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [fetchingProfileData, setFetchingProfileData] = useState(false);

  const [changePasswordData, setChangePasswordData] = useState({
    old_password: '',
    new_password: '',
  });
  const [changePasswordError, setChangePasswordError] = useState('');


  useEffect(() => {
    // Fetch user data from the API and update the 'profileData' state
    fetchProfileData();
  }, []);

  const resetForm = () => {
    setErrors({});
    setSelectedPhoto(null);
    setIsLoading(false);
  }

  const fetchProfileData = async () => {
    try {
      setFetchingProfileData(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/${token}`);
      const data = await response.json();
      setProfileData(data); // Update the 'profileData' state with the fetched data
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setFetchingProfileData(false);
    }
  };

  const handleProfileUpdate = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set the loading state to true

    try {
      const editableFields = ['email', 'phone', 'name', 'address'];
      const formData = new FormData();
      for (const field of editableFields) {
        if (profileData[field]) {
          formData.append(field, profileData[field]);
        }
      }


      if (selectedPhoto) {
        formData.append('photo', selectedPhoto);
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/${token}`, {
        method: 'PUT',
        body: formData,
      });
      const data = await response.json();

      if (response.ok) {
        // Profile update successful

        // Handle success, display a success message, update the UI accordingly
        toast.success('Profile updated successfully'); // Display success toast message
        // Reset the form
        resetForm()

        // Update profile data
        handleProfileUpdated(data)
      } else {
        // Profile update failed, handle errors
        setErrors(data);
      }
    } catch (error) {
      console.error('Error updating user profile', error);
      toast.error('Something went wrong!'); // Show error toast
    }
    finally {
      setIsLoading(false); // Set the loading state to false after the request completes
    }
  };

  const handleFileChange = (event) => {
    const { name, value, files } = event.target;

    if (name === 'photo') {
      const photoFile = files[0];
      setSelectedPhoto(photoFile); // Store the selected photo file in the state
    } else {
      setProfileData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };


  // Password
  const handleChangePasswordSubmit = async (event) => {
    event.preventDefault();
    setChangePasswordError('');
    setChangePasswordLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'access-key': token,
        },
        body: JSON.stringify(changePasswordData),
      });

      if (response.ok) {
        // Password change successful
        toast.success('Password changed successfully');
      } else if (response.status === 400) {
        // Password change failed due to validation error or incorrect old password
        const errorData = await response.json();
        setChangePasswordError(errorData.error);
        toast.error('Failed to change password');
      } else {
        // Other error occurred
        toast.error('Failed to change password');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      toast.error('An error occurred');
    } finally {
      setChangePasswordData(false)
      setChangePasswordData({
        old_password: '',
        new_password: '',
      });
    }
  };

  const getProfilePhotoUrl = () => {
    if (selectedPhoto)
      return URL.createObjectURL(selectedPhoto)


    return `${process.env.REACT_APP_API_URL}/${currentProfilePhoto}`;
  }

  return (
    <div className="m-auto md:w-2/3 lg:w-2/3 xl:w-2/3 relative flex justify-center items-center transition-all relative">
      <div className="bg-white rounded-lg shadow-lg transition-all">
        <h2 className="text-primary-700 text-2xl font-bold mb-4">Update your profile</h2>
        <form onSubmit={handleProfileUpdate} className='transition-all'>
          <div className='mb-4 flex relative'>
            <p className='bg-gray-100 py-2 text-primary-500'>If you don't select a photo, your profile picture will not change</p>
            <img src={getProfilePhotoUrl()} className='h-24 w-24 w-1/4 absolute right-0 top-0 rounded-xl' />
          </div>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2 text-primary-700">
              Name
            </label>
            <input
              type="text"
              id="name"
              required={true}
              name="name"
              value={profileData?.name || ''}
              onChange={handleInputChange}
              className="border-b border-primary-700 px-2 py-1 w-80 outline-0"
            />
            {errors?.name && <p className="text-red-500">{errors.name}</p>}
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block mb-2 text-primary-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              required={true}
              name="email"
              value={profileData?.email || ''}
              onChange={handleInputChange}
              className="border-b border-primary-700 px-2 py-1 w-80 outline-0"
            />
            {errors?.email && <p className="text-red-500">{errors.email}</p>}
          </div>

          <div className="mb-4">
            <label htmlFor="phone" className="block mb-2 text-primary-700">
              Phone
            </label>
            <input
              type="text"
              id="phone"
              required={true}
              name="phone"
              value={profileData?.phone || ''}
              onChange={handleInputChange}
              className="border-b border-primary-700 px-2 py-1 w-80 outline-0"
            />
            {errors?.phone && <p className="text-red-500">{errors.phone}</p>}
          </div>

          <div className="mb-4">
            <label htmlFor="address" className="block mb-2 text-primary-700">
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={profileData?.address || ''}
              onChange={handleInputChange}
              className="border-b border-primary-700 px-2 py-1 w-80 outline-0"
            />
            {errors?.address && <p className="text-red-500">{errors.address}</p>}
          </div>


          <div className="mb-4">
            <label htmlFor="photo" className="block mb-2 text-primary-700">
              Profile picture
            </label>
            <div className="items-center mt-2">
              <div className='flex'>
                <label
                  htmlFor="upload"
                  className="text-center text-primary-700  py-2 rounded-md cursor-pointer hover:bg-gray-100 hover:text-primary-500 transition-all"
                >
                  <CloudArrowUpIcon className="h-5 w-5 m-auto" />
                  <span className="text-primary-700 mr-2">
                    {selectedPhoto ? selectedPhoto.name : 'Upload image'}
                  </span>
                </label>
                <input
                  id="upload"
                  type="file"
                  name="photo"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>


          <button
            type="submit"
            className="bg-primary-500 text-white px-4 py-2 rounded-full transition-all border border-white ring-primary-500 hover:bg-primary-700 hover:ring-2"
            disabled={isLoading} // Disable the button when isLoading is true
          >
            {isLoading ? '...' : 'Update Profile'}
          </button>
        </form>



        {/* Password */}
        <form onSubmit={handleChangePasswordSubmit} className='mt-12 shadow-md border-l-4 border-yellow-500 pl-4'>
          <h2 className="text-primary-500 text-lg font-bold mb-2">Change Password</h2>
          <div className='mb-4'>
            <p className='bg-primary-100 px-1 py-2 text-primary-700'>N.B the change password is completely separated from the form above and will not get affected by submitting that form</p>
          </div>
          <div className="my-4">
            <input
              type="password"
              id="old_password"
              required={true}
              name="old_password"
              placeholder="Old Password"
              value={changePasswordData.old_password}
              onChange={(e) =>
                setChangePasswordData((prevData) => ({
                  ...prevData,
                  old_password: e.target.value,
                }))
              }
              className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
            />
            <input
              type="password"
              id="new_password"
              name="new_password"
              required={true}
              placeholder="New Password"
              value={changePasswordData.new_password}
              onChange={(e) =>
                setChangePasswordData((prevData) => ({
                  ...prevData,
                  new_password: e.target.value,
                }))
              }
              className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
            />
            {changePasswordError && (
              <span className="text-red-500">{changePasswordError}</span>
            )}
            <button
              type="submit"
              className="bg-primary-300 text-white px-4 py-2 rounded-full transition-all border border-white ring-primary-500 hover:bg-primary-500 hover:ring-2 mt-2"
              disabled={changePasswordLoading} // Disable the button when isLoading is true
            >
              {changePasswordLoading ? '...' : 'Change Password'}
            </button>
          </div>
        </form>
      </div>

      <ToastContainer />

      {(fetchingProfileData || changePasswordLoading) && <Loader height={'auto'} width={'fit-content'} />}
    </div>
  );
}
