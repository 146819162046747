import React from "react";
import { Route, Routes } from "react-router-dom";
import News from "./pages/news";
import Plannings from "./pages/plannings";
import Profile from "./pages/profile";
import Employees from "./pages/employees";
import AddEmployee from "./pages/add-employee";
import DOAccounts from "./pages/do-accounts";
import AddDOAccount from "./pages/add-do-account";
import DODroplets from "./pages/do-droplets";
import NewDroplet from "./pages/add-droplet";
import SpfFinder from "./pages/spf-finder";
import ChangeSlaveTemplate from './pages/change-slave-template'

//import Item from './pages/Item';



export default function Router({ profileData, handleProfileUpdated }) {
  return (
    <Routes>
      <Route exact path="/" element={<News token={profileData.token} profileData={profileData} />} />
      <Route exact path="/news" element={<News token={profileData.token} profileData={profileData} />} />
      <Route exact path="/dashboard" element={<News token={profileData.token} profileData={profileData} />} />

      <Route exact path="/plannings" element={<Plannings connectedUser={profileData} token={profileData.token} />} />
      <Route exact path="/profile" element={<Profile currentProfilePhoto={profileData.photo} handleProfileUpdated={handleProfileUpdated} token={profileData.token} />} />
      <Route exact path="/employees" element={<Employees token={profileData.token} connectedUserUid={profileData.uid} connectedUser={profileData} />} />
      <Route exact path="/add-employee" element={<AddEmployee token={profileData.token} />} />
      <Route exact path="/do-accounts" element={<DOAccounts token={profileData.token} />} />
      <Route exact path="/add-do-account" element={<AddDOAccount token={profileData.token} />} />
      <Route exact path="/do-accounts/:id" element={<DODroplets connectedUser={profileData} />} />
      <Route exact path="/do-accounts/:id/add-droplet" element={<NewDroplet connectedUser={profileData} />} />
      <Route exact path="/spf-finder" element={<SpfFinder connectedUser={profileData} />} />
      <Route exact path="/change-slave-template" element={<ChangeSlaveTemplate connectedUser={profileData} />} />

      {/* <Route path="/item/:id" element={<Item />} /> */}
    </Routes >
  );
}
