import React, { useState, useEffect } from 'react';
import { PlusCircleIcon, TrashIcon, PencilIcon, UserCircleIcon, UserIcon, CloudIcon, ArrowLongLeftIcon, ArrowLeftIcon, ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Loader } from '../components/common';
import DropletOverview from '../components/do-droplet-overview'
import { GroupAnalysis } from '../components/SVGs';
import { Bg500 } from '../components/common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';

export default function DODroplets({ connectedUser }) {
  const [droplets, setDroplets] = useState([]);
  const [DOAccount, setDOAccount] = useState([]);
  const [selectedDroplet, setSelectedDroplet] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams()

  useEffect(() => {
    // fetchAccount();
    fetchDroplets();
  }, []);

  const fetchAccount = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}/do-accounts?do_account_id=${id}`;

      const nameFilter = null;
      if (nameFilter) {
        url += `?name=${nameFilter}`;
      }

      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'access-key': connectedUser.token,
        },
      });
      const data = await response.json();
      setDOAccount(data.do_accounts ? data.do_accounts[0] : null);
    } catch (error) {
      console.error('Error fetching do account:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDroplets = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}/droplets?do_account_id=${id}`;

      const nameFilter = null;
      if (nameFilter) {
        url += `?name=${nameFilter}`;
      }

      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'access-key': connectedUser.token,
        },
      });
      const { do_droplets, db_droplets } = await response.json();
      let formattedDroplets = do_droplets.map(droplet => mergeDroplet(droplet, db_droplets))
      setDroplets(formattedDroplets);
    } catch (error) {
      console.error('Error fetching droplets:', error);
    } finally {
      setLoading(false);
    }
  };

  const mergeDroplet = (doData, dbDroplets) => {
    const droplet = {
      disk: doData.disk,
      id: doData.id,
      image: doData.image.description,
      ram: doData.memory / 1024,
      networks: doData.networks.v4.filter(net => net.type === 'public'),
      region: doData.region.name,
      cpu: doData.size.vcpus,
      status: doData.status,
      name: doData.name,
    };
    const dbDropletIndex = Object.keys(dbDroplets).findIndex(index => doData.id == dbDroplets[index]['do_droplet_id'])
    if (dbDropletIndex >= 0) {
      let dbDroplet = dbDroplets[dbDropletIndex];
      droplet['ssh_password'] = dbDroplet.ssh_password;
      droplet['ssh_user'] = dbDroplet.ssh_user;
      droplet['backend_id'] = dbDroplet.id;
      droplet['name'] = dbDroplet.name;
    }

    return droplet
  }

  const deleteDroplet = async (id) => {
    try {
      setLoading(true)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/droplets?droplet_id=${id}`, {
        method: 'DELETE',
        headers: {
          'access-key': connectedUser.token,
        }
      });
      if (response.ok) {
        // Unselect the account if it has been deleted
        if (selectedDroplet && selectedDroplet.backend_id === id)
          setSelectedDroplet(null);

        // Show success message
        toast.success('The droplet has been deleted successfully');

        // Remove from the display
        setDroplets(droplets.filter(x => x.backend_id !== id))

      } else {
        console.error('Error deleting droplet:', response.status);
        toast.error('Failed deleting droplet');
      }
    } catch (error) {
      console.error('Error deleting droplet:', error);
      toast.error('Failed deleting droplet');
    } finally {
      setLoading(false);
    }
  };

  const getColor = (name) => {

    const char = (name.slice(0, 1) || '').toLowerCase();
    // abcde fghij klmno pqrst uvwxyz
    if (char < 'f') return Bg500('gray');
    if (char < 'k') return Bg500('blue');
    if (char < 'p') return Bg500('green');
    if (char < 'u') return Bg500('orange');

    return Bg500('red')
  }

  const updateDropletPassword = (dropletBackendId, newPassword) => {
    setDroplets(droplets.map(d => d.backend_id !== dropletBackendId ? d : { ...d, ssh_password: newPassword }));
  }

  return (
    <div className="m-auto px-8 md:w-full lg:w-full xl:w-full justify-center items-center relative">
      <div className='relative mb-4'>
        <Link
          to="add-droplet"
          className='transition-all items-center flex text-primary-500 absolute top-0 right-0 px-2 py-1 border border-gray-300 cursor-pointer hover:border-primary-300 rounded-full hover:bg-white'
        >
          <CloudIcon className='h-8 w-8' />
          <span className='text-md p-1'>New</span>
        </Link>
      </div>
      <h1 className="text-primary-700 text-2xl font-bold mb-4 w-full flex items-center">
        <Link to={`/do-accounts`} className='cursor-pointer'>
          <ArrowSmallLeftIcon className='h-6 w-6 text-primary-500 mr-4 rounded-full border hover:border-primary-500' />
        </Link>
        <span className='ml-2'>Droplets</span>
      </h1>

      <div className='w-full bg-gray-100 block md:flex lg:flex xl:flex'>
        <div className="bg-gray-300 shadow-lg w-1/4 px-8 py-2 mt-4">
          <ul className=''>
            {droplets &&
              droplets.map((droplet) => (
                <li className={(selectedDroplet && droplet.id === selectedDroplet.id ? ' border-primary-500 ' : '') + ` border-l-4 flex py-4 bg-gray-100 items-center transition-all mb-1  relative `} key={droplet.id}>
                  <div onClick={() => { setSelectedDroplet(droplet) }}
                    className={(droplet && droplet.id === droplet.id ? ' border-primary-500 ' : ' border-gray-300 ') + `flex items-center m-auto mx-2 rounded-full border hover:border-primary-500 transition-all cursor-pointer`}>
                    <p className={`h-14 w-14 ${getColor(droplet.name)} text-2xl text-white rounded-full border-2 border-gray-500 pt-2 text-center uppercase`}>
                      {droplet.name.slice(0, 2)}
                    </p>
                  </div>

                  <p className='p-1 mx-2 text-base'>{droplet.name}</p>

                  <div className='w-1/4 flex  items-center absolute right-0'>
                    <TrashIcon
                      className='m-1 h-10 w-10 border hover:border-red-500 hover:text-red-500 rounded-full p-1 transition-all cursor-pointer text-gray-500'
                      onClick={() => deleteDroplet(droplet.backend_id)}
                    />
                  </div>
                </li>
              ))}
          </ul>
        </div>

        {/* Selected account's droplets */}
        <div className='w-3/4'>
          {
            selectedDroplet ? <DropletOverview updateDropletPassword={updateDropletPassword} selectedDroplet={selectedDroplet} connectedUser={connectedUser} />
              : <div className='text-center items-center m-auto'>
                <GroupAnalysis className='text-center items-center m-auto' />
              </div>
          }
        </div>
      </div>

      {(loading) && <Loader height={'auto'} width={'fit-content'} />}
      <ToastContainer />
    </div>
  );
}
