import React, { useState, useEffect } from 'react';
import { PlusCircleIcon, TrashIcon, PencilIcon, UserCircleIcon, UserIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Loader } from '../components/common';
import PlanningsComponent from '../components/plannings'
import { VisionaryTech } from '../components/SVGs';

export default function Employees({ token, connectedUser }) {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}/user`;

      const nameFilter = null;
      if (nameFilter) {
        url += `?name=${nameFilter}`;
      }

      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'access-key': token,
        },
      });
      const data = await response.json();
      setEmployees(data.users);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteEmployee = async (uid) => {
    if (connectedUser.uid == uid && !window.confirm('You\'re about to suicide! Are you sure you want to delete your account ?')) {
      return
    }

    try {
      setLoading(true)
      const formData = new FormData();
      formData.append('uid', uid);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
        method: 'DELETE',
        headers: {
          'access-key': token,
        },
        body: formData
      });
      if (response.ok) {
        setSelectedEmployee(null);

        setEmployees(employees.filter(emp => emp.uid !== uid))

        // Since the connected user's account has been deleted, sign him out
        if (connectedUser.uid == uid) {
          localStorage.removeItem('token');
          window.location.href = window.location.origin + '/signin'
        }
      } else {
        console.error('Error deleting employee:', response.status);
      }
    } catch (error) {
      console.error('Error deleting employee:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="m-auto px-8 md:w-full lg:w-full xl:w-full justify-center items-center relative">
      <h1 className="text-primary-700 text-2xl font-bold mb-4 w-full">Employees {selectedEmployee ? `: ${selectedEmployee.name}` : ''}</h1>

      <div className='w-full bg-gray-100 block md:flex lg:flex xl:flex'>
        <div className="bg-gray-100 shadow-lg w-1/4 px-8 py-2 mt-4">
          <div className='relative mb-12'>
            <Link
              to="/add-employee"
              className='transition-all items-center flex text-primary-500 absolute top-0 right-10 px-2 py-1 border border-gray-300 cursor-pointer hover:border-primary-300 rounded-full hover:bg-white'
            >
              <UserIcon className='h-8 w-8' />
              <span className='text-md p-1'>Add</span>
            </Link>
          </div>
          <ul className=''>
            {employees &&
              employees.map((employee) => (
                <li className={(selectedEmployee && selectedEmployee.uid === employee.uid ? ' border-primary-500 ' : '') + ` border-l-4 flex py-4 bg-gray-100 items-center transition-all mb-1  relative `} key={employee.uid}>
                  <div onClick={() => { setSelectedEmployee(employee) }}
                    className={(selectedEmployee && selectedEmployee.uid === employee.uid ? ' border-primary-500 ' : ' border-gray-300 ') + `flex items-center m-auto mx-2 rounded-full border hover:border-primary-500 transition-all`}>
                    {employee.photo ? (
                      <img className='h-14 w-14 rounded-full' title={employee.email} src={`${process.env.REACT_APP_API_URL}/${employee.photo}`} />
                    ) : (
                      <UserIcon className="h-14 w-14 text-primary-500 rounded-full" />
                    )}
                  </div>

                  <p className='p-1 mx-2 text-base'>{employee.name}</p>

                  <div className='w-1/4 flex  items-center absolute right-0'>
                    <TrashIcon
                      className='m-1 h-10 w-10 border hover:border-red-500 hover:text-red-500 rounded-full p-1 transition-all cursor-pointer text-gray-500'
                      onClick={() => deleteEmployee(employee.uid)}
                    />
                  </div>
                </li>
              ))}
          </ul>
        </div>

        {/* Selected user's plannings */}
        <div className='w-3/4'>
          {
            selectedEmployee ? <PlanningsComponent token={token} isAdmin={connectedUser.role.name === 'admin'} employee={selectedEmployee} />
              : <div className='text-center items-center m-auto'>
                <VisionaryTech className='text-center items-center m-auto' />
              </div>
          }
        </div>
      </div>

      {(loading) && <Loader height={'auto'} width={'fit-content'} />}
    </div>
  );
}
