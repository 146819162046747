import React, { useState, useEffect } from 'react';
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export default function AddDOAccount({ token }) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [DOAccountData, setDOAccountData] = useState({
    email: '',
    password: '',
    token: '',
  });

  useEffect(() => {
    // 
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDOAccountData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData()
      Object.keys(DOAccountData).map(key => {
        formData.append(key, DOAccountData[key])
      })

      const response = await fetch(`${process.env.REACT_APP_API_URL}/do-accounts`, {
        method: 'POST',
        headers: {
          'access-key': token,
          'content-type': 'application/json',
          'access-token': token
        },
        body: JSON.stringify(DOAccountData),
      });

      if (response.ok) {
        // DOAccount creation successful
        toast.success('Digital Ocean account added successfully');
        navigate('/do-accounts')
      } else if (response.status >= 400 && response.status < 500) {
        // DOAccount creation failed due to validation errors
        const errorData = await response.json();
        setValidationErrors(errorData);
        toast.error('Failed to add Digital Ocean account');
      } else {
        // Other error occurred
        toast.error('Failed to create digital ocean account');
      }
    } catch (error) {
      console.error('Error creating digital ocean account:', error);
      toast.error('An error occurred');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="flex justify-center items-center relative">
      <div className="bg-white p-8 rounded-lg shadow-lg w-2/3">
        <h2 className="text-primary-500 text-2xl font-bold mb-4 flex items-center ">
          <Link to="/do-accounts" className='cursor-pointer'>
            <ArrowSmallLeftIcon className='h-6 w-6 text-primary-500 mr-4 rounded-full border hover:border-primary-500' />
          </Link>
          New Digital Ocean Account
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2 text-primary-500">
              Email
            </label>
            <input
              type="email"
              id="email"
              required={true}
              name="email"
              value={DOAccountData.email}
              onChange={handleInputChange}
              className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
            />
            <p className='py-2 text-red-500 mt-2 rounded'>
              {validationErrors?.email && <span className="text-red-500">{validationErrors.email}</span>}
            </p>
          </div>

          <div className="mb-4">
            <label htmlFor="phone" className="block mb-2 text-primary-500">
              Password
            </label>
            <input
              type="password"
              id="password"
              required={true}
              name="password"
              value={DOAccountData.password}
              onChange={handleInputChange}
              className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
            />
            <p className='py-2 text-red-500 mt-2 rounded'>
              {validationErrors?.password && <span className="text-red-500">{validationErrors.password}</span>}
            </p>
          </div>

          <div className="mb-4">
            <label htmlFor="token" className="block mb-2 text-primary-500">
              Token
            </label>
            <input
              type="text"
              id="token"
              required={true}
              name="token"
              value={DOAccountData.token}
              onChange={handleInputChange}
              className="border-b border-primary-500 px-2 py-1 w-80 outline-0"
            />
            <p className='py-2 text-red-500 mt-2 rounded'>
              {validationErrors?.token && <span className="text-red-500">{validationErrors.token}</span>}
            </p>
          </div>


          <button
            type="submit"
            className="bg-primary-500 text-white px-4 py-2 rounded-full transition-all border border-white ring-primary-500 hover:bg-primary-500 hover:ring-2"
            disabled={isLoading}
          >
            {isLoading ? '...' : 'Save'}
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );

}
