import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const response = await fetch(process.env.REACT_APP_API_URL + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: email,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Authentication successful
        const { token } = data;

        // Store the token in cookies or localStorage
        localStorage.setItem("token", token);

        // Redirect the user to the dashboard after successful signin
        window.location.href = window.location.origin + "/dashboard";
      } else {
        // Authentication failed
        setErrorMessage(data.username);
      }
    } catch (error) {
      console.error("Error during authentication:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-2xl font-semibold mb-4 text-primary-700">Welcome in ;)</h1>
      <form onSubmit={handleSubmit} className="w-full max-w-sm">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Username"
            id="email"
            value={email}
            required={true}
            onChange={(e) => setEmail(e.target.value)}
            className="form-input mt-1 border-b border-primary-700 text-primary-700 py-3 outline-0 w-full text-base"
          />
        </div>
        <div className="mb-6">
          <input
            placeholder="password"
            type="password"
            id="password"
            value={password}
            required={true}
            onChange={(e) => setPassword(e.target.value)}
            className="form-input mt-1 border-b border-primary-700 text-primary-700 py-3 outline-0 w-full text-base"
          />
        </div>
        {errorMessage && (
          <div className="text-red-500 mb-4">{errorMessage}</div>
        )}
        <div className="w-full text-center relative">
          <button
            type="submit"
            className={"absolute right-0 w-28 flex transition-all bg-primary-500 text-white rounded-md py-2 px-4 hover:bg-primary-700 focus:outline-none focus:bg-primary-700 m-auto"}
            disabled={isLoading}
          >
            {isLoading ? (
              <BeatLoader color={"white"} size={16}/>
            ) : (
              <>
                <span>Sign in</span> <ArrowRightIcon className="h-6 w-6 ml-1 rounded-full bg-primary-500 border-gray-300 hover:border-primary-500" />
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
