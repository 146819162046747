import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DayPlanningModal, Loader } from '../components/common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PlanningsComponent({ employee, token, isAdmin, startRange, endRange }) {
    const [startOfWeek, setStartOfWeek] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null)
    const [planningsData, setPlanningsData] = useState({});
    const employeesPage = /employee/.test(window.location.pathname);
    const [editableWeek, setEditableWeek] = useState({})


    if (!startRange && !endRange) {
        const today = new Date();
        startRange = new Date(today);
        startRange.setDate(startRange.getDate() - 21); // Three weeks ago
        endRange = new Date(today);
        endRange.setDate(endRange.getDate() + 14); // Two weeks from now
    }


    useEffect(() => {
        setEditableWeek(getEditableWeek());
        fetchPlanningsData();

    }, [employee]);


    /**
     * @returns Object
     * construct an object of next week as follows: {d/m/yyyy: null, d+1/m/yyyy: null,...}
     */
    const getEditableWeek = () => {
        const today = new Date();
        const lastDayOfThisWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7));
        const nextWeek = {};
        for (let i = 0; i < 7; i++) {
            lastDayOfThisWeek.setDate(lastDayOfThisWeek.getDate() + 1);
            let key = lastDayOfThisWeek.toLocaleDateString();
            nextWeek[key] = Object.keys(planningsData).includes(key) ? planningsData[key] : null;
        }
        return nextWeek;
    }


    const handleDateClick = (arg) => {
        // Handle date click event here
        const clickedDate = new Date(arg.dateStr)
        if (!Object.keys(editableWeek).includes(clickedDate.toLocaleDateString())) {
            toast.info('You can only change the upcoming week\'s plannings!');
            return;
        }
        setSelectedDay(clickedDate.toLocaleDateString());
    };

    /**
     * @return Boolean
     * Returns true if it detects any changes made to any day of the editableWeek
     */
    const planningsHaveBeenChanged = () => {
        for (var i = 0; i < Object.keys(planningsData).length; i++) {
            var day = Object.keys(planningsData)[i];
            if (Object.keys(editableWeek).includes(day) && planningsData[day] != editableWeek[day])
                return true;
        }
        return Object.keys(planningsData).length > 0;
    }


    const [loadingPlanningsData, setLoadingPlanningsData] = useState(false);

    const fetchPlanningsData = async () => {
        setLoadingPlanningsData(true)
        try {
            const url = new URL(`${process.env.REACT_APP_API_URL}/plannings`);

            // Fetching current month plannings
            // const today = new Date();
            // today.setDate(1);
            // const firstDayOfTheMonth = today;
            // const lastDayOfTheMonth = (new Date(today.getFullYear(), today.getMonth() + 1, 0));

            const payload = {
                start_date: `${startRange.getDate()}/${startRange.getMonth() + 1}/${startRange.getFullYear()}`,
                end_date: `${endRange.getDate()}/${endRange.getMonth() + 1}/${endRange.getFullYear()}`,
                employee_uid: employee.uid,
            };
            Object.keys(payload).map(key => url.searchParams.append(key, payload[key]));

            const response = await fetch(url.href, {
                method: 'GET',
                headers: {
                    'access-key': token,
                },
            });

            if (response.ok) {
                const data = await response.json();

                // Format planningsData accordingly
                const formattedData = [];
                const editableWeekData = getEditableWeek();
                data.plannings.map(item => {
                    let d = new Date(item.date)
                    formattedData[d.toLocaleDateString()] = item.status;

                    const forattedDayKey = d.toLocaleDateString();
                    const shouldEdit = Object.keys(editableWeekData).includes(forattedDayKey);
                    if (shouldEdit) {
                        setEditableWeek((prev) => {
                            return { ...prev, [forattedDayKey]: item.status };
                        });
                    }
                })
                setPlanningsData(formattedData);
            } else if (response.status === 400) {
                toast.error('Failed loading plannings!');
            } else {
                // Other error occurred
                toast.error('Failed loading plannings!');
            }
        } catch (error) {
            toast.error('An error occurred while loading plannings!');
        } finally {
            setLoadingPlanningsData(false)
        }
    }

    const handleDayCellContent = (arg) => {
        const dayPlanning = planningsData[arg.date.toLocaleDateString()];
        let className = 'px-2 pt-2 w-24 h-24';

        if (dayPlanning == 'off') {
            className += ' bg-gray-200 text-red-500';
        } else if (['10h-18h', '10h-20h', '12h-22h'].includes(dayPlanning)) {
            className += ' bg-primary-200 text-primary-700';
        } else if (Object.keys(editableWeek).includes(arg.date.toLocaleDateString())) {
            className += ' bg-primary-500 text-primary-100';
        } else {
            className += ' bg-gray-100 text-gray-700';
        }

        return (
            <div className={className}>
                <p className='text-base mb-2'>
                    {arg.date.getDate()}
                    <sub>{(new Date(arg.date)).toLocaleDateString('en-US', { weekday: 'long' })}</sub>
                </p>
                <p className=''>{dayPlanning}</p>
            </div>
        )
    };

    const updatePlanning = (day, value) => {
        const _planningsData = { ...planningsData }
        _planningsData[day] = value
        setPlanningsData(_planningsData)

        const _editableWeek = { ...editableWeek }
        _editableWeek[day] = value
        setEditableWeek(_editableWeek)
        setSelectedDay(null)
    }

    const formatDate = (date) => {
        const d = new Date(date)
        return d.toLocaleDateString('en-US', {
            day: 'numeric',
            weekday: 'short',
            month: 'short',
        })
    }

    /**
     * @returns Object
     * return editableWeek data as {'%d/%m/%Y':dayPlanningValue}
     */
    const formatDates = () => {
        const weekData = {}
        const test = Object.keys(editableWeek).map(key => {
            const date = new Date(key);
            const day = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

            // Only add records where their values are not null
            if (!!editableWeek[key]) {
                weekData[day] = editableWeek[key];
            }
        })
        return weekData;
    }

    const [savingPlanningsData, setSavingPlanningsData] = useState(false);
    const submitPlanningChanges = async () => {
        if (!planningsHaveBeenChanged()) {
            toast.warning('Nothing to save!');
            return;
        }

        // Verify days off count validity
        let daysOffCount = 0;
        let has8HShift = false;
        Object.keys(editableWeek).map(key => {
            daysOffCount += editableWeek[key] === 'off' ? 1 : 0;
            has8HShift = editableWeek[key] === '10h-18h' || has8HShift;
        })
        console.warn({
            daysOffCount,
            has8HShift,
            data: editableWeek,
            postData: formatDates(),
        })

        if (has8HShift && daysOffCount > 1) {
            toast.warning('Employees with 8 hours shift (10h-18h) could only have 1 day off per week!')
            return;
        }

        if (daysOffCount > 2) {
            toast.warning('You\'ve exceeded the maximum days off per week!')
            return;
        }

        setSavingPlanningsData(true)
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}/plannings`;

            const payload = {
                employee_uid: employee.uid,
                week_data: formatDates(),
            }
            // Object.keys(payload).map(key => formData.append(key, payload[key]));
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'access-key': token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                toast.success('Plannings successfully saved.');
            } else if (response.status === 400) {
                toast.error('Failed saving plannings!');
            } else {
                // Other error occurred
                toast.error('Failed saving plannings!');
            }
        } catch (error) {
            toast.error('An error occurred while saving plannings!');
        } finally {
            setSavingPlanningsData(false)
        }
    }


    return (
        <div className="m-auto w-full justify-center items-center relative">
            <div className="bg-white rounded-lg shadow-lg">
                {
                    employeesPage && <h2 className="text-primary-700 text-2xl font-bold mb-4">{employee.name}</h2>
                }
                <div className=''>
                    <FullCalendar
                        height={'auto'}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridWeek"
                        dateClick={handleDateClick}
                        selectable={true}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: (`dayGridWeek,dayGridMonth`) + (!!isAdmin ? ' saveButton' : ''),
                        }}
                        weekNumberCalculation={'ISO'}
                        views={{
                            dayGridWeek: {
                                type: 'dayGrid',
                                firstDay: 1,
                                duration: { weeks: 1 },
                            },
                            dayGridMonth: {
                                type: 'dayGrid',
                                firstDay: 1,
                                duration: { months: 1 },
                            },
                        }}
                        validRange={() => {
                            return { startRange, endRange };
                        }}
                        dayCellContent={handleDayCellContent}
                        customButtons={{
                            saveButton: {
                                hint: 'Save the changes made',
                                text: 'Save',
                                click: () => { submitPlanningChanges() },
                            },
                        }}
                    />
                </div>
            </div>

            {
                (selectedDay && <DayPlanningModal showFooter={!!isAdmin} formatDate={formatDate} key={Math.floor(Math.random() * 10)} currentShift={Object.keys(planningsData).includes(selectedDay) ? planningsData[selectedDay] : null} handleSubmit={(value) => { updatePlanning(selectedDay, value) }} week={editableWeek} selectedDay={selectedDay} close={() => setSelectedDay(null)} />)
            }

            <ToastContainer />

            {(loadingPlanningsData || savingPlanningsData) && <Loader height={'auto'} width={'fit-content'} />}

        </div>
    );
}
