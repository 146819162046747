import './App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Router from './Routes';
import LeftMenu from './components/LeftMenu';
import Signin from "./pages/signin";
import { BrowserRouter } from 'react-router-dom';
import { Loader } from './components/common';

function AbsenceApp() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profileData, setProfileData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchProfileData(token);
    } else {
      setIsLoading(false)
    }
  }, []);

  const fetchProfileData = async (token) => {
    setIsLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${token}`
      );

      response.json().then(data => {
        setIsAuthenticated(true);
        setProfileData({ ...data, token });
        // navigate to the dashboard route
        navigate('/');
      })

      if (!response.ok) {
        setIsAuthenticated(false);
        // navigate to the sign-in route
        navigate('/signin');
      }
    } catch (error) {
      console.error("Error during profile retrieval:", error);
      setIsAuthenticated(false);
      // navigate to the sign-in route
      navigate('/signin');
    } finally {
      setIsLoading(false)
    }
  };

  const handleSignIn = (token) => {
    localStorage.setItem('token', token);
    fetchProfileData(token);
  };

  const handleSignOut = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    // navigate to the sign-in route
    navigate('/signin');
  };

  const handleProfileUpdated = (data) => {
    data.token = profileData.token
    setProfileData(data)
  }

  const renderContent = () => {
    if (isAuthenticated)
      return (
        <div>
          <LeftMenu profileData={profileData} signOut={handleSignOut} />
          <div className='bg-transparent h-screen px-4 md:mt-16 lg:mt-16 xl:mt-16 pt-8'>
            <Router handleProfileUpdated={handleProfileUpdated} profileData={profileData} />
          </div>
        </div>
      );

    if (!isLoading && !isAuthenticated) return <Signin onSignIn={handleSignIn} />;

    return (<div className='h-screen w-full relative'>
      {(isLoading && <Loader height={'auto'} width={'fit-content'} />)}
    </div>);
  }

  return (
    renderContent()
  );
}

function App() {
  return (
    <BrowserRouter>
      <AbsenceApp />
    </BrowserRouter>
  )
}

export default App;