import { ArrowDownOnSquareStackIcon, CheckIcon, CircleStackIcon, ComputerDesktopIcon, CpuChipIcon, HomeModernIcon, KeyIcon, MapPinIcon, PencilIcon, PresentationChartLineIcon, TagIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { Loader } from '../components/common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DropletOverview({ connectedUser, selectedDroplet, updateDropletPassword }) {
  const [newPassword, setNewPassword] = useState(null);
  const [editableFields, setEditableFields] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [overviewData, setOverviewData] = useState(selectedDroplet);

  const getStatusColor = () => {
    if (overviewData.status.toLowerCase() === 'new') {
      return `p-2 rounded-full w-fit flex text-primary-500 bg-primary-100`;
    }
    return `p-2 rounded-full w-fit flex ` + (overviewData.status === 'active' ? ' border-emerald-500 text-emerald-500 bg-emerald-100 ' : ' border-orange-500 text-orange-500 bg-orange-100');
  }

  const handleChangePassword = async () => {
    setIsLoading(true);
    if (!newPassword) {
      toast.warning('Empty values are not allowed!');
      return;
    }
    if (newPassword === overviewData.ssh_password) {
      toast.warning('No changes have been detected!');
      return;
    }

    if (!overviewData.networks.length) {
      toast.warning('This droplet has an issue with the network connectivity!');
      return;
    }

    try {
      const payload = {
        "droplet_id": overviewData.backend_id,
        "droplet_primary_ip": overviewData.networks[0]['ip_address'],
        "ssh_password": newPassword
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/droplets`, {
        method: 'PUT',
        headers: {
          'access-key': connectedUser.token,
          'content-type': 'application/json',
          'access-token': connectedUser.token
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setEditableFields(editableFields.filter(x => x !== 'ssh_password'))
        toast.success('The root password has been changed successfully');
        // Update parent accordingly
        updateDropletPassword(overviewData.backend_id, newPassword)
        // Update the UI
        setOverviewData({ ...overviewData, ssh_password: newPassword })
      } else if (response.status >= 400 && response.status < 500) {
        // DO Droplet creation failed due to validation errors
        toast.warning('Password has not been changed!');
      } else {
        // Other error occurred
        toast.error('Something went wrong!');
      }
    } catch (error) {
      console.error('Error changing digital ocean droplet password:', error);
      toast.error('An error occurred');
    } finally {
      setIsLoading(false);
    }
  }

  const genRandPassword = (passwordLength) => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789$.@-!:';
    let passwd = '';
    for (var i = 0; i < passwordLength; i++) {
      let randIndex = Math.floor(Math.random() * chars.length);
      passwd += chars[randIndex];
    }

    return passwd;
  }

  return (
    <div className='p-4 block'>

      <div className='mb-4 shadow-md'>
        <div className={getStatusColor()}>
          <TagIcon className='h-6 w-6 ' />
          <span className='ml-2'>{overviewData.name}</span>
          <span className='ml-2'>{overviewData.status.toLowerCase() === 'new' ? 'In progress' : overviewData.status}</span>
        </div>
      </div>


      {
        selectedDroplet && overviewData.image && (
          <div className='ml-4 mt-1'>
            <div className='w-full flex mb-4 shadow-sm'>
              <div className='w-1/2 flex'>
                <div className='w-1/4 flex'>
                  <ComputerDesktopIcon className='h-6 w-6 ' />
                  <span className='ml-2'>OS</span>
                </div>
                <p className=''>{overviewData.image}</p>
              </div>
            </div>

            <div className='w-full flex mb-4 shadow-sm'>
              <div className='w-1/2 flex'>
                <div className='w-1/4 flex'>
                  <CpuChipIcon className='h-6 w-6 ' />
                  <span className='ml-2'>Memory</span>
                </div>
                <p className=''>{overviewData.ram}GB</p>
              </div>

              <div className='w-1/2 flex'>
                <div className='w-1/4 flex'>
                  <CpuChipIcon className='h-6 w-6 ' />
                  <span className='ml-2'>CPU</span>
                </div>
                <p className=''>{overviewData.cpu} vcpu</p>
              </div>
            </div>

            <div className='w-full flex mb-4 shadow-sm'>
              <div className='w-1/2 flex'>
                <div className='w-1/4 flex'>
                  <CircleStackIcon className='h-6 w-6 ' />
                  <span className='ml-2'>Disk Storage</span>
                </div>
                <p className=''>{overviewData.disk}GB</p>
              </div>
            </div>

            <div className='w-full flex mb-4 shadow-sm'>
              <div className='w-1/2 flex'>
                <div className='w-1/4 flex'>
                  <MapPinIcon className='h-6 w-6 ' />
                  <span className='ml-2'>Region</span>
                </div>
                <p className=''>{overviewData.region}</p>
              </div>
            </div>


            <div className='w-full flex mb-4 shadow-sm'>
              <div className='w-1/2 flex'>
                <div className='w-1/4 flex'>
                  <UserIcon className='h-6 w-6 ' />
                  <span className='ml-2'>SSH User</span>
                </div>
                <p className='px-4 bg-primary-100 text-primary-500 rounded-full -ml-4'>{overviewData.ssh_user}</p>
              </div>

              <div className='w-1/2 flex'>
                <div className='w-1/4 flex'>
                  <KeyIcon className='h-6 w-6 ' />
                  <span className='ml-2'>SSH Password</span>
                </div>
                <div className='flex'>
                  {
                    !editableFields.includes('ssh_password')
                      ? <p className='px-4 bg-primary-100 text-primary-500 rounded-full -ml-4'>{overviewData.ssh_password !== '--' ? overviewData.ssh_password : ''}</p>
                      : <input value={newPassword} className='w-1/2 outline-0 border border-b-primary-300 pt-2 px-1' type='text' onChange={(e) => { setNewPassword(e.target.value) }} />
                  }
                  <button className=''>
                    {
                      !editableFields.includes('ssh_password')
                        ? <div className='ml-4'>
                          <PencilIcon className='h-6 w-6 text-primary-500' onClick={() => { setNewPassword(genRandPassword(12)); setEditableFields([...editableFields, 'ssh_password']) }} />
                        </div>
                        : <div className='flex ml-4'>
                          <CheckIcon className='h-6 w-6 text-primary-500 mr-2' onClick={handleChangePassword} />
                          <XMarkIcon className='h-6 w-6 text-primary-500' onClick={() => { setEditableFields(editableFields.filter(x => x !== 'ssh_password')) }} />
                        </div>
                    }
                  </button>
                </div>
              </div>
            </div>

            <div className='w-full flex mb-4 shadow-sm'>
              <div className='w-1/2 flex'>
                <div className='w-1/4 flex'>
                  <HomeModernIcon className='h-6 w-6 ' />
                  <span className='ml-2'>Main IP</span>
                </div>
                <p className='px-4 bg-primary-100 text-primary-500 rounded-full -ml-4'>{overviewData.networks.length ? overviewData.networks[0]['ip_address'] : '--'}</p>
              </div>

              <div className='w-1/2 flex'>
                <div className='w-1/4 flex'>
                  <PresentationChartLineIcon className='h-6 w-6 ' />
                  <span className='ml-2'>IPs count</span>
                </div>
                <p className=''>{overviewData.networks ? overviewData.networks.length : 0}</p>
              </div>
            </div>


            <div className='w-full flex mt-4'>
              <div className=''>
                <div className=' flex'>
                  <ArrowDownOnSquareStackIcon className='h-6 w-6 ' />
                  <span className='ml-2'>All IP addresses</span>
                </div>
                <ul className='pl-12 mt-2'>
                  {overviewData.networks ? overviewData.networks.map(net => (
                    <li className='list-disc py-2' key={net.ip_address}>{net.ip_address}</li>
                  )) : ''}
                </ul>
              </div>
            </div>
          </div >
        )
      }

      {(isLoading) && <Loader height={'auto'} width={'fit-content'} />}
      <ToastContainer />
    </div >
  );
}