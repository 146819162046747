import React, { useState, useEffect } from 'react';
import { CloudArrowUpIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from '../components/common';

export default function SpfFinder({ connectedUser }) {
  const [profileData, setProfileData] = useState({});
  const [errors, setErrors] = useState('');
  const [selectedSpfFile, setSelectedSpfFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingSpfFilesNames, setFetchingSpfFilesNames] = useState(false);
  const [spfFiles, setSpfFiles] = useState([]);
  const [spfSearchResult, setSpfSearchResult] = useState([]);

  // Upload new SPF file
  const [uploadingNewSpfFile, setUploadingNewSpfFile] = useState(false);

  useEffect(() => {
    fetchSpfFilesNames();
  }, []);

  const resetForm = () => {
    setErrors('');
    setSelectedSpfFile(null);
    setIsLoading(false);
  }

  const fetchSpfFilesNames = async () => {
    try {
      setFetchingSpfFilesNames(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/spf-finder`, {
        headers: {
          'access-key': connectedUser.token,
        },
      });
      const data = await response.json();
      setSpfFiles(data);
    } catch (error) {
      console.error('Error fetching spf data:', error);
    } finally {
      setFetchingSpfFilesNames(false);
    }
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const usp = new URLSearchParams();
      usp.append('ip-address', profileData['ipaddress']);
      usp.append('selected-file', profileData['spffile']);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/spf-finder?${usp.toString()}`, {
        method: 'GET',
        headers: {
          'access-key': connectedUser.token,
        },
      });
      const data = await response.json();

      if (response.ok) {
        setSpfSearchResult(data);
      } else {
        // Profile update failed, handle errors
        toast.warning('Please verify your inputs!');
      }
    } catch (error) {
      console.error('Error updating user profile', error);
      toast.error('Something went wrong!'); // Show error toast
    }
    finally {
      setIsLoading(false); // Set the loading state to false after the request completes
    }
  };

  const handleFileChange = (event) => {
    const { name, value, files } = event.target;

    if (name === 'spf-file') {
      const file = files[0];
      setSelectedSpfFile(file); // Store the selected file in the state
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    setUploadingNewSpfFile(true);
    try {
      const formData = new FormData();
      if (selectedSpfFile) {
        formData.append('spf-file', selectedSpfFile);
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/spf-finder`, {
        method: 'POST',
        headers: {
          'access-key': connectedUser.token,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success('File uploaded successfully');
        // Refetch smf files names
        fetchSpfFilesNames();
        // Reset the form
        resetForm()
      } else {
        // Profile update failed, handle errors
        setErrors('Failed uploading file, please try again!');
      }
    } catch (error) {
      console.error('Error uploading spf data file', error);
      toast.error('Something went wrong!'); // Show error toast
    }
    finally {
      setUploadingNewSpfFile(false); // Set the loading state to false after the request completes
    }

  }

  return (
    <div className="m-auto md:w-3/4 lg:w-3/4 xl:w-3/4 relative flex justify-center items-center transition-all relative">
      <div className="bg-white rounded-lg shadow-lg transition-all">
        <h2 className="text-primary-700 text-2xl font-bold mb-4">SPF Finder</h2>
        <form onSubmit={handleSearchSubmit} className='transition-all'>
          <div className="mb-8">
            <label htmlFor="name" className="block mb-2 text-primary-700">
              Select an SPF file
            </label>
            <div className='w-auto flex'>
              <select
                id="spffile"
                required={true}
                name="spffile"
                onChange={handleInputChange}
                className="px-2 py-1 w-1/2 outline-0 border-none bg-white"
              >
                <option value={''}>Select file</option>
                {
                  spfFiles.map(file => <option value={file} key={file}>{file}</option>)
                }
              </select>
            </div>
            {
              !spfFiles.length &&
              (<p className='text-orange-500 p-1'>
                Start by uploading an SPF data file from the form below!
              </p>)
            }
          </div>


          <div className="mb-4">
            <label htmlFor="ipaddress" className="block mb-2 text-primary-700">
              IP Address
            </label>
            <div className='flex'>
              <input
                type="text"
                id="ipaddress"
                required={true}
                name="ipaddress"
                placeholder="IP address .."
                value={profileData?.ipaddress || ''}
                onChange={handleInputChange}
                className="px-2 py-1 w-1/2 outline-0 border-b border-primary-300"
              />
              <button
                type="submit"
                className="bg-primary-500 text-white px-2 py-1 rounded-md transition-all hover:bg-primary-700"
                disabled={isLoading} // Disable the button when isLoading is true
              >
                {isLoading ? '...' : <div className='flex'>
                  Search <MagnifyingGlassIcon className='h-6 w-auto ml-2' />
                </div>}
              </button>
            </div>
          </div>
        </form>


        {/* Search result */}
        <div className=''>
          <textarea className='w-full h-48 outline-none border-b border-primary-300'
            defaultValue={spfSearchResult.join('\t\n')} readOnly={true}></textarea>
        </div>


        {/* Upload new file */}
        <form onSubmit={handleUploadFile} className='mt-12 border-t shadow-md border-l-4 border-yellow-500 pl-4'>

          <div className="mb-4">
            <label htmlFor="spf-file" className="block mb-2 text-primary-700">
              SPF file
            </label>
            <div className="items-center mt-2">
              <div className='flex'>
                <label
                  htmlFor="upload"
                  className="text-center text-primary-700  py-2 rounded-md cursor-pointer hover:bg-gray-100 hover:text-primary-500 transition-all"
                >
                  <CloudArrowUpIcon className="h-5 w-5 m-auto" />
                  <span className="text-primary-700 mr-2">
                    {selectedSpfFile ? selectedSpfFile.name : 'Select file'}
                  </span>
                </label>
                <input
                  id="upload"
                  type="file"
                  name="spf-file"
                  accept="txt/*"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>


          <h2 className="text-primary-500 text-lg font-bold mb-2">Upload SPF data</h2>
          <div className='mb-4'>
            <p className='bg-primary-100 px-1 py-2 text-primary-700'>Upload SPF check result data then use the search form to find your IP Address matching records</p>
          </div>
          <div className="my-4">
            {errors && (
              <p className="text-red-500">{errors}</p>
            )}

            <button
              type="submit"
              className="bg-primary-500 text-white px-4 py-2 rounded-full transition-all border border-white ring-primary-500 hover:bg-primary-700 hover:ring-2"
              disabled={uploadingNewSpfFile || !selectedSpfFile} // Disable the button when isLoading is true
            >
              {uploadingNewSpfFile ? '...' : 'Upload'}
            </button>
          </div>
        </form>
      </div>

      <ToastContainer />

      {(fetchingSpfFilesNames) && <Loader height={'auto'} width={'fit-content'} />}
    </div>
  );
}
