import React, { useState } from 'react';
import { BarLoader, BeatLoader, BounceLoader, ClimbingBoxLoader, ClipLoader, ClockLoader, PacmanLoader, PropagateLoader, PulseLoader, PuffLoader, RingLoader, ScaleLoader, SyncLoader } from 'react-spinners';
import { CheckIcon, XMarkIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline';

export function DayPlanningModal({ showFooter, handleSubmit, selectedDay, close, week, currentShift, formatDate }) {
    const [dayIsOff, markDayAsOff] = useState(currentShift === 'off')

    // To make the logic easier, we remove the day in subject from the daysOfTheWeek array
    // const daysOfTheWeek = Object.keys(week);
    const daysOfTheWeek = Object.keys(week).filter(k => k != selectedDay);

    const [shift, setShift] = useState(currentShift);
    const shifts = [
        '10h-18h',
        '10h-20h',
        '12h-22h',
    ];

    const handleFormSubmit = (e) => {
        e.preventDefault()
        handleSubmit(dayIsOff ? 'off' : shift)
    };

    /**
     * Constraint:
     *  - employees with 8hours shift can only have 1 day off!
     *  - Maximum days off is 2 per week!
     */
    const toggleDayOff = () => {
        let has8HShift = false;
        let daysOff = 0;
        daysOfTheWeek.map(key => {
            daysOff += (week[key] === 'off') ? 1 : 0;
            has8HShift = week[key] === '10h-18h' || has8HShift && week[key] !== selectedDay;
        });


        // Prevent marking a day as off if there are already 2 days marked as off or employee has at least 1 8h shift
        if (!dayIsOff && (daysOff > 1 || (has8HShift && daysOff > 0))) {
            return;
        }

        markDayAsOff(!dayIsOff);
    }

    const getShiftCssClasses = (shift) => {
        let daysOffCount = 0;
        daysOfTheWeek.map(key => {
            daysOffCount += (week[key] !== selectedDay && week[key] === 'off') ? 1 : 0;
        });

        if (daysOffCount > 1 && shift === '10h-18h') {
            // Cannot select 8hours shift
            return 'bg-gray-100 hover:border-yellow-300 border border-gray-300 text-gray-500 hover:text-gray-600 h-20 items-center justify-center pt-6 rounded-full border w-20 py-2 items-center text-center m-auto cursor-pointer transition-all relative';
        }

        return 'bg-white hover:border-primary-500 border border-gray-200 text-primary-500 hover:text-primary-500 h-20 items-center justify-center pt-6 rounded-full border w-20 py-2 items-center text-center m-auto cursor-pointer transition-all relative';
    }

    const changeShift = (newShift) => {
        /**
         * Constraints:
         * if the employee has already chosen 2 days off he shouldn't be able to select the 8hours shift!
         */

        // Check if the weekPlannings contains 8hours shift
        let index = 0;
        daysOfTheWeek.map(key => {
            index += (week[key] === 'off') ? 1 : 0;
        });

        if (index > 1 && newShift === '10h-18h') {
            return;
        }

        setShift(newShift)
    }

    return (
        <div className="w-auto">
            <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-10'>
                <form onSubmit={handleFormSubmit} className="bg-gray-100 shadow-md rounded-lg mb-4 md:w-1/3 lg:w-1/3">
                    {/* Title */}
                    <h2 className={`transition-all flex items-center shadow text-2xl text-left p-4 rounded-lg ` + (dayIsOff ? ' bg-gray-700 ' : ' bg-gray-200 ')}>
                        <p className={' transition-all ' + (dayIsOff ? ' text-primary-100 ' : ' text-primary-700 ')}>{(new Date(selectedDay)).toLocaleDateString('en-US', { weekday: 'long' })}</p>
                        <p className='w-full text-center'>
                            <button onClick={toggleDayOff} type="button" className='h-10 w-24 text-base p-2 border-2 border-primary-100 text-primary-500
                            hover:border-primary-300 hover:bg-white bg-gray-100 rounded-full transition-all'>Turn {dayIsOff ? 'on' : 'off'}</button>
                        </p>
                        <p className='w-40 ml-2 py-2 px-4 bg-primary-100 rounded-full text-primary-700 text-sm'>{selectedDay}</p>
                    </h2>

                    {/* Content */}
                    <div className='p-4 '>
                        <div className='flex mb-8 '>
                            <p className='bg-white border-primary-500 text-primary-500 rounded-full border w-64 py-2 items-center text-center m-auto'>{formatDate(daysOfTheWeek[0])}</p>
                            <div className='w-full'>
                                <ArrowLongRightIcon className='w-full h-12 text-primary-500' />
                            </div>
                            <p className='bg-white border-primary-500 text-primary-500 rounded-full border w-64 py-2 items-center text-center m-auto'>{formatDate(daysOfTheWeek[daysOfTheWeek.length - 1])}</p>
                        </div>

                        {/* Shifts */}
                        <div className='flex w-2/3 m-auto'>
                            {
                                shifts.map(_shift => <p key={_shift} onClick={() => changeShift(_shift)} className={getShiftCssClasses(_shift)}>
                                    {_shift}
                                    {(_shift === shift && <CheckIcon className='h-8 w-8 text-primary-500 absolute -top-1 -right-2' />)}
                                </p>)
                            }
                        </div>

                        {/* Instructions */}
                        <div className='py-3 border-l-4 border-orange-300 bg-orange-100 px-2 mt-8'>
                            <div className='flex items-center'>
                                <div className='h-3 w-3 bg-orange-500 mr-2 rounded-full'></div>
                                <p className='text-orange-700'>The work shift of 8hours a day ship with only 1 day Off!</p>
                            </div>
                            {
                                showFooter && (<div className='flex mt-3 items-center'>
                                    <div className='h-3 w-3 bg-orange-500 mr-2 rounded-full'></div>
                                    <p className='text-orange-700'>When you don't select any shift, the day is considered as Off</p>
                                </div>
                                )}
                        </div>

                        <div className="flex items-center justify-end mt-4">
                            <button type="button"
                                onClick={() => close()}
                                className={(!showFooter ? '' : 'mr-12') + (" text-orange-500 hover:bg-orange-500 hover:text-white border-2 border-orange-500 rounded-full transition-all")}
                            >
                                <XMarkIcon className='h-10 w-10' />
                            </button>
                            {
                                showFooter && (
                                    <button
                                        type="submit"
                                        className="mr-4 text-primary-500 hover:bg-primary-500 hover:text-white border-2 border-primary-500 rounded-full transition-all"
                                    >
                                        <CheckIcon className='h-10 w-10' />
                                    </button>)
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}



export function Loader() {
    // Return one of these spinners randomly
    const spinners = {
        BarLoader: BarLoader,
        BeatLoader: BeatLoader,
        BounceLoader: BounceLoader,
        ClimbingBoxLoader: ClimbingBoxLoader,
        ClipLoader: ClipLoader,
        ClockLoader: ClockLoader,
        PacmanLoader: PacmanLoader,
        // PropagateLoader: PropagateLoader, // Gets out of his parent's container
        PulseLoader: PulseLoader,
        PuffLoader: PuffLoader,
        RingLoader: RingLoader,
        ScaleLoader: ScaleLoader,
        SyncLoader: SyncLoader,
    };

    const keys = Object.keys(spinners);
    const randSpinnerIndex = Math.floor(Math.random() * keys.length);
    const SpinnerComponent = spinners[keys[randSpinnerIndex]];

    return (
        <div className={`bg-gray-500 bg-opacity-50 absolute top-0 right-0 bottom-0 left-0 z-10`}>
            <div style={{ marginTop: '15%' }} className=' w-fit bg-gray-100 m-auto p-4 rounded-lg '>
                <SpinnerComponent className='w-fit h-fit ' color={'#27D'} />
            </div>
        </div>
    )
}



export function Bg100(color) {
    switch (color) {
        case 'gray':
            return `bg-gray-100`;
        case 'blue':
            return `bg-blue-100`;
        case 'green':
            return `bg-green-100`;
        case 'orange':
            return `bg-orange-100`;
        default:
            return `bg-red-100`;
    }
}
export function Border700(color) {
    switch (color) {
        case 'gray':
            return `border-gray-700`;
        case 'blue':
            return `border-blue-700`;
        case 'green':
            return `border-green-700`;
        case 'orange':
            return `border-orange-700`;
        default:
            return `border-red-700`;
    }
}
export function Text700(color) {
    switch (color) {
        case 'gray':
            return `text-gray-700`;
        case 'blue':
            return `text-blue-700`;
        case 'green':
            return `text-green-700`;
        case 'orange':
            return `text-orange-700`;
        default:
            return `text-red-700`;
    }
}
export function Bg300(color) {
    switch (color) {
        case 'gray':
            return `bg-gray-300`;
        case 'blue':
            return `bg-blue-300`;
        case 'green':
            return `bg-green-300`;
        case 'orange':
            return `bg-orange-300`;
        default:
            return `bg-red-300`;
    }
}

export function Bg500(color) {
    switch (color) {
        case 'gray':
            return `bg-gray-500`;
        case 'blue':
            return `bg-blue-500`;
        case 'green':
            return `bg-green-500`;
        case 'orange':
            return `bg-orange-500`;
        default:
            return `bg-red-500`;
    }
}