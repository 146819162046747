import React, { useState, useEffect } from 'react';
import { CheckIcon, CloudArrowUpIcon, EnvelopeIcon, MagnifyingGlassIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from '../components/common';


const ChangeSlaveTemplate = ({ connectedUser }) => {
    const [errors, setErrors] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [fetchingTemplates, setFetchingTemplates] = useState(false);
    const [templates, setTemplates] = useState([]);

    const [formFields, setFormFields] = useState({
        template_name: "",
        server_address_ip: "",
        server_port: "22",
        server_user: "root",
        server_password: ""
    });

    useEffect(() => {
        fetchTemplates();
    }, []);

    const resetForm = () => {
        setErrors('');
        setIsLoading(false);
    }

    const fetchTemplates = async () => {
        try {
            setFetchingTemplates(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/slaves-templates`, {
                headers: {
                    'access-key': connectedUser.token,
                },
            });
            const data = await response.json();
            setTemplates(data)
        } catch (error) {
            console.error('Error fetching templates:', error);
        } finally {
            setFetchingTemplates(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormFields({ ...formFields, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/slaves-templates`, {
                method: 'POST',
                headers: {
                    'access-key': connectedUser.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formFields)
            });
            if (response.ok) {
                toast.success('The template has been updated accordingly')
                resetForm()
            } else {
                toast.error('Cannot update the template, please try again')
            }
        } catch (error) {
            console.error('Cannot update the template:', error);
            toast.error('Cannot update the template, please try again')
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <div className="m-auto md:w-3/4 lg:w-3/4 xl:w-3/4 flex justify-center items-center transition-all relative">
            <div className="bg-white rounded-lg shadow-lg transition-all">
                <h2 className="text-primary-700 text-2xl font-bold mb-4">Change Slave Server Template</h2>
                <p className='py-2'>
                    This page allows you to change a given slave server's HTML template,<br />
                    <em>Assuming the slave server is installed from the master end and it's working properly!</em>
                </p>
                <form onSubmit={handleSubmit} className='transition-all my-2'>
                    <div className="mb-8">
                        <label htmlFor="name" className="block mb-2 text-primary-700">
                            Select one HTML Template
                        </label>
                        <div className='w-auto flex'>
                            <select
                                id="template_name"
                                required={true}
                                name="template_name"
                                onChange={handleInputChange}
                                className="px-2 py-1 w-1/2 outline-0 border-none bg-white"
                            >
                                <option value={''}>Select template</option>
                                {
                                    templates.map(templateName => <option value={templateName} key={templateName}>{templateName}</option>)
                                }
                            </select>
                        </div>
                        {
                            !templates.length &&
                            (<p className='text-orange-500 p-1'>
                                Upload some templates first!
                            </p>)
                        }
                    </div>


                    <div className="mb-4">
                        <label htmlFor="server_address_ip" className="block mb-2 text-primary-700">
                            IP Address
                        </label>
                        <div className='flex'>
                            <input
                                type="text"
                                id="server_address_ip"
                                required={true}
                                name="server_address_ip"
                                placeholder="IP address .."
                                value={formFields?.server_address_ip || ''}
                                onChange={handleInputChange}
                                className="px-2 py-1 w-1/2 outline-0 border-b border-primary-300"
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="server_port" className="block mb-2 text-primary-700">
                            Port
                        </label>
                        <div className='flex'>
                            <input
                                type="text"
                                id="server_port"
                                required={true}
                                name="server_port"
                                readOnly={true}
                                placeholder="Port .."
                                value={formFields?.server_port || ''}
                                onChange={handleInputChange}
                                className="px-2 py-1 w-1/2 outline-0 border-b border-primary-300"
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="server_user" className="block mb-2 text-primary-700">
                            Server user
                        </label>
                        <div className='flex'>
                            <input
                                type="text"
                                id="server_user"
                                required={true}
                                readOnly={true}
                                name="server_user"
                                placeholder="Server user .."
                                value={formFields?.server_user || ''}
                                onChange={handleInputChange}
                                className="px-2 py-1 w-1/2 outline-0 border-b border-primary-300"
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="server_password" className="block mb-2 text-primary-700">
                            Server password
                        </label>
                        <div className='flex'>
                            <input
                                type="text"
                                id="server_password"
                                required={true}
                                name="server_password"
                                placeholder="Server password .."
                                value={formFields?.server_password || ''}
                                onChange={handleInputChange}
                                className="px-2 py-1 w-1/2 outline-0 border-b border-primary-300"
                            />
                        </div>
                    </div>

                    <div className='mt-4'>
                        <button
                            type="submit"
                            className="bg-primary-500 text-white px-2 py-1 rounded-md transition-all hover:bg-primary-700"
                            disabled={isLoading} // Disable the button when isLoading is true
                        >
                            {isLoading ? '...' : <div className='flex'>
                                Submit <PaperAirplaneIcon className='h-6 w-auto ml-2' />
                            </div>}
                        </button>
                    </div>
                </form>

            </div>

            <ToastContainer />

            {(fetchingTemplates) && <Loader height={'auto'} width={'fit-content'} />}
        </div>

    )
}

export default ChangeSlaveTemplate;