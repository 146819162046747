import React, { useState, useEffect } from 'react';
import { PlusCircleIcon, TrashIcon, PencilIcon, UserCircleIcon, UserIcon, CloudIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Loader } from '../components/common';
import { Bg500 } from '../components/common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DOAccounts({ token }) {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}/do-accounts`;

      const nameFilter = null;
      if (nameFilter) {
        url += `?name=${nameFilter}`;
      }

      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'access-key': token,
        },
      });
      const data = await response.json();
      setAccounts(data.do_accounts);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteAccount = async (id) => {
    try {
      setLoading(true)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/do-accounts?do_account_id=${id}`, {
        method: 'DELETE',
        headers: {
          'access-key': token,
        }
      });
      if (response.ok) {
        // Unselect the account if it has been deleted
        if (selectedAccount && selectedAccount.id === id)
          setSelectedAccount(null);

        // Show success message
        toast.success('The account has been deleted successfully');
        // Remove from the display
        setAccounts(accounts.filter(x => x.id != id));
      } else {
        console.error('Error deleting do account:', response.status);
        toast.success('Failed deleting do account');
      }
    } catch (error) {
      console.error('Error deleting do account:', error);
      toast.success('Failed deleting do account');
    } finally {
      setLoading(false);
    }
  };

  const getColor = (email) => {

    const char = (email.slice(0, 1) || '').toLowerCase();
    // abcde fghij klmno pqrst uvwxyz
    if (char < 'f') return Bg500('gray');
    if (char < 'k') return Bg500('blue');
    if (char < 'p') return Bg500('green');
    if (char < 'u') return Bg500('orange');

    return Bg500('red')
  }

  return (
    <div className="m-auto w-full md:w-2/3 lg:w-2/3 xl:w-2/3 justify-center items-center relative">
      <div className='relative mb-4'>
        <Link
          to="/add-do-account"
          className='transition-all items-center flex text-primary-500 absolute top-0 right-0 px-2 py-1 border border-gray-300 cursor-pointer hover:border-primary-300 rounded-full hover:bg-white'
        >
          <CloudIcon className='h-8 w-8' />
          <span className='text-md p-1'>New</span>
        </Link>
      </div>
      <h1 className="text-primary-700 text-2xl font-bold mb-4 w-full">DO Accounts</h1>

      <div className='w-full bg-gray-100 block md:flex lg:flex xl:flex'>
        <div className="bg-gray-100 shadow-lg w-full px-8">
          <ul className=''>
            {accounts &&
              accounts.map((account) => (
                <li className={(selectedAccount && selectedAccount.id === account.id ? ' border-primary-500 ' : '') + ` border-l-4 flex py-4 bg-gray-100 items-center transition-all mb-1  relative `} key={account.id}>
                  <Link to={`/do-accounts/${account.id}`}>
                    <div onClick={() => { setSelectedAccount(account) }}
                      className={(selectedAccount && selectedAccount.id === account.id ? ' border-primary-500 ' : ' border-gray-300 ') + `flex items-center m-auto mx-2 rounded-full border hover:border-primary-500 transition-all cursor-pointer`}>
                      <p className={`h-14 w-14 ${getColor(account.email)} text-2xl text-white rounded-full border-2 border-gray-500 pt-2 text-center uppercase`}>
                        {account.email.slice(0, 2)}
                      </p>
                    </div>
                  </Link>

                  <p className='p-1 mx-2 text-base'>{account.email}</p>

                  <div className=' flex  items-center absolute right-0'>
                    <TrashIcon
                      className='m-1 h-10 w-10 border hover:border-red-500 hover:text-red-500 rounded-full p-1 transition-all cursor-pointer text-gray-500'
                      onClick={() => deleteAccount(account.id)}
                    />
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>

      {(loading) && <Loader height={'auto'} width={'fit-content'} />}
      <ToastContainer />
    </div>
  );
}
